.cardMouseHover:hover{
    background-color: antiquewhite;
    border-color: burlywood;
}

.blink{
    animation: blink 1.5s step-start 0s infinite;
}

@keyframes blink {
    50% {
      opacity: 0.0;
    }
  }