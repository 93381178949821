.App {
  display: flex;
  width: 100%;
  align-items: stretch; 
  overflow: auto;
}

body {  
  background: #e6dada;  
  overflow: auto;

  margin: 0;
  font-family: 'Segoe UI'!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.92rem !important;
  background-color: #f2f7fb !important;
}

.footer-copyright{
  color: aliceblue;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  bottom: 0;
  /* position: absolute; */
  width: 100%;
  background-color: #263544;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 220px;
  max-width: 220px;
  background: #263544;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
  height: 100%;  
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background: #263544;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background: #263544;
}

.nav-item:hover {
  color: #263544;
  background: rgba(255, 255, 255, 0.062);
  cursor: default;
}

.items-menu {
  color: #fff;
  background: #263544;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

li a.dropdown-toggle {
  color: #fff;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu {
  height: calc(100vh - 130px);
  /* overflow-y: scroll; */
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #263544;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #263544;
}

.sidebar-header hr{ display: block; height: 1px;
  border: 0; border-top: 1px solid #ccc;
  margin: 1em 0; padding: 0; }

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  /* padding: 20px;
  margin-left: 0;
  height: 100vh; */
}

.react-date-picker__wrapper{
  border: none !important;
}

@media only screen and (max-width: 500px) {
  body {
    /* overflow: hidden; */
  }

  .sidebar.is-open {
    margin-left: 100%;
    min-width:50%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }


}

@media only screen and (max-width: 1100px) {
  .footer-copyright{
    position: fixed;    
  }
}
